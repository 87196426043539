.header {
	width: 100%;
	height: 250px;
	padding: 35px 20px;
	padding-bottom: 0;
	background-color: var(--c-light);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
	transition: all 200ms;
}
@media screen and (min-width: 768px) {
	.header {
		padding: 30px 200px;
		padding-bottom: 0;
	}
}
@media screen and (min-width: 1300px) {
	.header {
		padding: 30px 400px;
		padding-bottom: 0;
	}
}

.btnHolder {
	margin-top: 16px;
}

.socialIcons {
	width: 100%;
	margin-top: 16px;
	display: flex;
	align-items: center;
}

.socialIcon {
	background-color: var(--c-darkPurpule);
	color: var(--c-light);
	padding: 18px;
	clip-path: circle(40%);
	margin-right: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	transition: all 200ms;
}
@media screen and (min-width: 768px) {
	.socialIcon {
		padding: 20px;
		font-size: 22px;
	}
}
.socialIcon:active {
	transform: scale(0.9);
}
