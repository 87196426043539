.links {
	width: 100%;
	padding: 10px 20px;
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
	transition: all 200ms;
}
@media screen and (min-width: 768px) {
	.links {
		padding: 10px 200px;
	}
}
@media screen and (min-width: 1300px) {
	.links {
		padding: 10px 400px;
	}
}
