.events {
	width: 100%;
	padding: 270px 20px 10px 20px;
	transition: all 200ms;
}
.tags {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	padding: 4px 0;
}

.tag {
	width: fit-content;
	padding: 4px 8px;
	background-color: var(--c-light);
	border: 1px solid var(--c-darkPurpule);
	border-radius: 4px;
	color: var(--c-darkPurpule);
	font-weight: 600;
	margin-right: 4px;
	cursor: pointer;
	font-size: 14px;
}

.tag.selected {
	background-color: var(--c-darkPurpule);
	color: var(--c-light);
}

.upcomingEvents {
	width: 100%;
	display: flex;
	overflow-x: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	padding: 4px 0;
}

/* .upcomingEvents::-webkit-scrollbar {
	display: none;
} */

.seeMore {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	font-weight: 500;
	background-color: var(--c-darkPurpule);
	color: var(--c-light);
	padding: 12px 18px;
	border-radius: 4px;
	white-space: nowrap;
	width: fit-content;
	transition: all 200ms ease-in-out;
	cursor: pointer;
}
.seeMore .link {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.seeMore .link .icon {
	padding: 18px;
	background-color: var(--c-primary);
	color: var(--c-light);
	clip-path: circle(40%);
	display: none;
}
.seeMore .link .emoji {
	padding: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--c-primary);
	color: var(--c-light);
	clip-path: circle(40%);
}

.previousEvents {
	width: 100%;
	display: flex;
	overflow-x: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	padding: 4px 0;
}

/* .previousEvents::-webkit-scrollbar {
	display: none;
} */
.upcomingEvents::-webkit-scrollbar {
	display: block;
	height: 8px;
	border-radius: 16px;
}

.upcomingEvents::-webkit-scrollbar-track {
	background: var(--c-light);
}

.upcomingEvents::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--c-darkPurpule);
}

.upcomingEvents::-webkit-scrollbar-thumb:hover {
	background: #4b4a6b;
}
.previousEvents::-webkit-scrollbar {
	display: block;
	height: 8px;
	border-radius: 16px;
}

.previousEvents::-webkit-scrollbar-track {
	background: var(--c-light);
}

.previousEvents::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--c-darkPurpule);
}

.previousEvents::-webkit-scrollbar-thumb:hover {
	background: #4b4a6b;
}

@media screen and (min-width: 768px) {
	.events {
		padding: 260px 200px 10px 200px;
	}

	.tag {
		padding: 4px 12px;
		font-size: 16px;
	}

	.upcomingEvents {
		overflow-x: scroll;
	}

	.previousEvents {
		overflow-x: scroll;
	}
}
@media screen and (min-width: 1300px) {
	.events {
		padding: 260px 400px 10px 400px;
	}
}
