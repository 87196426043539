:root {
	--c-primary: #5865f2;
	--c-darkPurpule: #292841;
	--c-light: #f6f6f6;
	--c-success: #00e175;
	--c-yellow: #ffbd00;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	font-family: 'Poppins', sans-serif !important;
	background: var(--c-light);
}

a {
	text-decoration: none;
	color: inherit;
}

.btn {
	width: fit-content;
	border-radius: 4px;
	font-size: 16px;
	padding: 8px 12px;
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	font-weight: 600;
	transition: all 200ms ease-in-out;
}

.btn:hover {
	filter: contrast(130%);
}

.btn:active {
	transform: scale(0.97);
	filter: contrast(110%);
}

.btn-primary {
	background-color: var(--c-primary);
	border: 2px solid var(--c-primary);
	color: var(--c-light);
}

.btn-secondary {
	background-color: var(--c-light);
	border: 2px solid var(--c-primary);
	color: var(--c-primary);
}
.btn-success {
	background-color: var(--c-success);
	border: 1px solid var(--c-success);
	color: var(--c-darkPurpule);
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
