.links .link {
	width: 100%;
	margin: 4px 0;
	padding: 8px 16px;
	background-color: var(--c-darkPurpule);
	color: var(--c-light);
	display: flex;
	border-radius: 4px;
	justify-content: space-between;
	align-items: center;
	font-size: 16px;
	transition: all 200ms;
}
.links .link:hover {
	filter: contrast(120%);
}
.links .link:active {
	transform: scale(0.99);
	filter: contrast(110%);
}

.links .link .icon {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 18px;
	background-color: var(--c-primary);
	color: var(--c-light);
	clip-path: circle(40%);
}
@media screen and (min-width: 768px) {
	.links .link {
		font-size: 18px;
		font-weight: 500;
	}
}
