.card {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background-color: var(--c-darkPurpule);
	color: var(--c-light);
	box-shadow: 0px 2px 20px 0px #7289da38, -0.5px -0.5px 1px 0px #fcfcfc inset,
		1px 1px 1px 0px rgba(114, 137, 218, 0.02);
	padding: 4px;
	border-radius: 4px;
	width: 250px;
	min-width: 250px;
	margin-right: 8px;
	transition: all 200ms ease-in-out;
}

.card .img {
	display: flex;
	justify-content: center;
	align-items: center;
}
.card .img img {
	width: 100%;
	border-radius: 4px;
}

.card .info {
	margin-top: 4px;
	padding: 0px 8px;
	flex: 1;
}

.card .info .datetime {
	margin: 4px 0;
	padding: 2px 0;
	font-size: 16px;
	font-weight: 500;
	color: var(--c-light);
}
.card .btnHolder {
	padding: 0 4px;
	margin-top: 4px;
	margin-bottom: 4px;
}
.card .btnHolder .btn {
	width: 100%;
}
